import request from '@/utils/request'

// 新建用户标签
export function ugcSearchList (data) {
  return request({
    url: '/user/ugc/search/list',
    data
  })
}
// ugc 视频 审核
export function updateUgcVerify (data) {
  return request({
    url: '/user/ugc/verify',
    data
  })
}

// ugc 视频 审核
export function updateShowStatus (data) {
  return request({
    url: '/user/ugc/change/show/status',
    data
  })
}

// ugc 视频 删除
export function deleteVideoStatus (data) {
  return request({
    url: '/user/ugc/change/delete/status',
    data
  })
}
