<template>
  <Layout class="UgcVideo">
    <h4>待审核视频: <span class="number">{{ listInfo.unCheckNum }}</span> 个</h4>
    <BHeader>
      <el-form
        :model="formData"
        inline
      >
        <el-form-item label="审核状态">
          <el-select v-model="formData.status">
            <el-option
              v-for="(item, index) in verifyStateList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input
            v-model="formData.userId"
            placeholder="用户ID"
            @keyup.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item label="游戏sourceId">
          <el-input
            v-model="formData.gameSourceId"
            placeholder="sourceId"
            @keyup.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
    >
      <!-- <el-table-column
        prop="No"
        label="编号"
      /> -->
      <el-table-column
        prop="gameSourceId"
        label="游戏sourceId"
        width="120px"
      />
      <el-table-column
        prop="gameName"
        label="游戏名称"
        width="180px"
      />
      <el-table-column
        prop="video"
        label="URL"
        width="190"
      >
        <template v-slot="{row}">
          <a
            :href="parserURL(row.video)"
            target="_blank"
          >
            {{ row.video }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        label="视频预览"
        width="140"
      >
        <template #default="{row}">
          <div
            v-if="row.spiderStatus && !row.deleteStatus"
            class="video-body"
            style="width: 120px; height: 60px"
          >
            <el-image
              style="width: 100%;height: 100%;"
              :src="row.ytbImg"
              fit="cover"
            >
              <div
                slot="placeholder"
                class="image-slot"
              >
                <i class="el-icon-loading" />
              </div>
              <div
                slot="error"
                class="image-slot"
              >
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
            <div class="cover">
              <i
                class="el-icon-video-play video-play"
                title="Play"
                @click="handlePlayVideo(row)"
              />
            </div>
          </div>
          <div v-else>
            None
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="spiderStatus"
        label="爬取状态"
        width="100"
      >
        <template #default="{row}">
          {{ row.spiderStatus ? '视频已爬取' : '视频未爬取' }}
        </template>
      </el-table-column>

      <el-table-column
        prop="deleteStatus"
        label="删除状态"
      >
        <template #default="{row}">
          {{ row.deleteStatus ? '已删除' : '正常' }}
        </template>
      </el-table-column>

      <el-table-column
        prop="nickName"
        label="用户昵称"
      />
      <el-table-column
        prop="userIdStr"
        label="用户ID"
        width="180px"
      />
      <el-table-column
        prop="status"
        label="状态"
      >
        <template v-slot="{row}">
          {{ verifyStateMap[row.status] || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="最后更新"
        width="180px"
      />
      <el-table-column
        label="操作"
        width="300px"
        fixed="right"
      >
        <template #default="{row}">
          <template v-if="row.status === APPROVE.notReviewed || row.status === APPROVE.unapproved">
            <el-button
              :loading="row.loading"
              type="success"
              @click="handlePass(row, APPROVE.approved)"
            >
              通过
            </el-button>
            <el-button
              v-if="row.status !== APPROVE.unapproved"
              :loading="row.loading"
              @click="handlePass(row, APPROVE.unapproved)"
            >
              不通过
            </el-button>
          </template>
          <template v-else>
            <el-button
              :type="row.showStatus === 0 ? 'primary': 'danger'"
              :loading="row.loading"
              @click="handleToggleState(row)"
            >
              {{ row.showStatus === 0 ? '上架' : '下架' }}
            </el-button>
          </template>
          <el-button
            v-if="row.spiderStatus && !row.deleteStatus"
            @click="handleDeleteVideo(row)"
          >
            删除视频
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryData(formData)"
    />

    <el-dialog
      :title="currentRows.gameName"
      :visible.sync="playVideo"
      :center="true"
      top="80px"
      width="90%"
    >
      <div class="play-wrapper">
        <video
          v-if="playVideo"
          width="100%"
          height="100%"
          :poster="currentRows.ytbImg"
          autoplay
          controls
        >
          <source :src="currentRows.ytbVideoUrl">
          Your browser does not support video tags
        </video>
      </div>
    </el-dialog>
  </Layout>
</template>

<script>
import {
  ugcSearchList,
  updateUgcVerify,
  updateShowStatus,
  deleteVideoStatus
} from '@/api/ugcVideo.js'
const APPROVE = {
  notReviewed: 1,
  approved: 2,
  unapproved: 3
}

export default {
  name: 'UgcVideo',
  data () {
    return {
      APPROVE,
      verifyStateList: [
        { name: '待审核', value: 1 },
        { name: '审核通过', value: 2 },
        { name: '审核未通过', value: 3 }
      ],
      verifyStateMap: {
        1: '待审核',
        2: '审核通过',
        3: '审核未通过'
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        loading: false,
        total: 0,
        unCheckNum: 0
      },
      playVideo: false,
      currentRows: {}
    }
  },
  created () {
    this.handleSearch()
  },
  methods: {
    parserURL (url) {
      if (!url) return url
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url
      }
      return url
    },
    handleSearch () {
      this.formData.page = 1
      this.queryData()
    },
    queryData () {
      this.listInfo.loading = true
      ugcSearchList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total
            this.listInfo.unCheckNum = res.data.unCheckNum
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handlePass (row, status) {
      this.$set(row, 'loading', true)
      updateUgcVerify({ id: row.id, status })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.queryData()
          }
        })
        .finally(() => {
          this.$set(row, 'loading', false)
        })
    },
    handleToggleState (row) {
      const nextStatus = row.showStatus === 0 ? 1 : 0
      this.$set(row, 'loading', true)
      updateShowStatus({ id: row.id, showStatus: nextStatus })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.$set(row, 'showStatus', nextStatus)
          }
        })
        .finally(() => {
          this.$set(row, 'loading', false)
        })
    },
    handlePlayVideo (row) {
      this.playVideo = true
      this.currentRows = row
    },
    handleDeleteVideo (row) {
      this.$confirm('确定删除该视频吗', '提示')
        .then(() => {
          deleteVideoStatus({
            id: row.id,
            deleteStatus: 1
          })
            .then(res => {
              if (res.code === 200) {
                this.queryData()
                this.$message.success('已删除')
              }
            })
        })
    }
  }
}
</script>

<style lang="less">
.UgcVideo {
  .number {
    color: blue;
  }
  .video-body {
        width: 100%;
        background-color: #F5F7FA;
        border-radius: 8px;
        background-size: cover;
        overflow: hidden;
        position: relative;
        &:hover {
          .cover {
            display: flex;
          }
        }
        .cover {
          display: none;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border-radius: 8px;

          .video-play {
            color: white;
            font-size: 28px;
            cursor: pointer;
          }
        }
      }
}
</style>
